<template>
    <v-card>
        <v-container>
            <v-row>
                <v-col>
                    <s-article-search 
                    v-model="article" 
                    label="Descripción del Artículo"
                    @selected="seletedData($event)"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        dense
                        fixed-header=""
                        :items="itemsSearch"
                        :headers="headersSearch"
                        :items-per-page="5"
                    ></v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";
    import OrderTraceability from "@/services/Logistics/OrderTraceability.js";

    export default {
        components: { 
            sArticleSearch
        },
        data() {
            return {
                article: null,
                ArtCode: 0,
                headersSearch: [
                    { text: "Nro de Pedido", value: "NroPedido" },
                    { text: "Fecha", value: "Fecha" },
                    { text: "Cantidad", value: "Cantidad" },
			    ],
                itemsSearch: [
                    {
                        NroPedido: 12345678,
                        Fecha: "2020-01-01",
                        Cantidad: 1
                    },
                    {
                        NroPedido: 12345678,
                        Fecha: "2020-01-01",
                        Cantidad: 1
                    }
                ],
            }
        },
        methods: {
            seletedData(article) {
                console.log("article :",article);
                this.ArtCode = article.ArtCode;

                OrderTraceability.list(this.ArtCode, this.$fun.getUserID())
                .then((r) => {               
                    if (r.status == 200) { 
                        // this.itemsSearch = r.data;
                        console.log("r.data", r.data);          
                    }
                });
            }
        }
    }
</script>